




































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";

.title {
  float: left;
  background: #064ca1;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 120%;
  color: #ffffff;
  text-indent: 10px;
  margin-bottom: 5px;
}
